"use client";

import { useContext, useRef, useState, useEffect } from "react";

import { usePathname } from "next/navigation";

import Link from "next/link";
import classNames from "classnames";

import { Container } from "../Container";
import { NSContext } from "@/components/context/NSProvider";

import * as styles from "./Navbar.module.scss";

const Navbar = () => {
  const mobileNavNode = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const { activeMenuItem, setActiveMenuItem, onOff, setOnOff } =
    useContext(NSContext);

  const pathname = usePathname();

  useEffect(() => {
    const type = pathname.split("/")[1];

    if (type === "exhibition" || type === "event" || type === "library") {
      setActiveMenuItem("show");
    } else {
      setActiveMenuItem(pathname.split("/")[1]);
    }
  });

  const toggleOnOff = (value) => setOnOff(!onOff);

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    setIsOpen(!isOpen);
  };

  const handleOnOff = () => {
    // toggle the active boolean in the state
    toggleOnOff();
  };

  const keyboardHandleOnOff = (e) => {
    // toggle the active boolean in the state
    if (e.keyCode === 13) {
      toggleOnOff();
    }
  };

  const handleClick = (e) => {
    if (mobileNavNode.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  const handleKeyboardDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      e.target.click();
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <Container className={styles.mobilePadding}>
      <nav
        className={classNames(styles.navbar)}
        id="navbar"
        role="navigation"
        aria-label="main-navigation"
      >
        <a className={styles.skipContent} href="#main">
          Skip to content
        </a>
        <div className="container">
          <div
            id="mobile-menu"
            className={styles.hamBurger}
            ref={mobileNavNode}
          >
            <div
              data-target="navMenu"
              onClick={toggleHamburger}
              onKeyDown={toggleHamburger}
              role="button"
              tabIndex="0"
              name="No Show Menu"
              title="No Show Menu"
              aria-label="Mobile menu"
              className={classNames(styles.menuBtn, {
                [styles.open]: isOpen === true,
              })}
            >
              <div role="button">
                <span className={styles.menuBtnLine} />
                <span className={styles.menuBtnLine} />
                <span className={styles.menuBtnLine} />
                <span className={styles.menuBtnLine} />
              </div>
            </div>
          </div>
          <div
            id="navMobile"
            className={classNames(
              styles.mobileMenu,
              {
                [styles.mobileOpen]: isOpen,
              },
              { [styles.mobileClosed]: !isOpen },
            )}
          >
            <div className={styles.mobileMenuInner}>
              <div>
                <Link
                  className={classNames(styles.navbarItem, `active`)}
                  href="/"
                  onClick={() => {
                    setActiveMenuItem("none");
                    setIsOpen(false);
                  }}
                  onKeyDown={() => {
                    setActiveMenuItem("none");
                    setIsOpen(false);
                  }}
                >
                  NO
                </Link>
              </div>
              <div className={styles.indented}>
                <div>
                  <Link
                    className={classNames(
                      styles.navbarItem,
                      `${activeMenuItem === "show" || activeMenuItem === "archive" ? "active" : ""}`,
                    )}
                    href="/show"
                    onClick={() => {
                      setActiveMenuItem("show");
                      setIsOpen(false);
                    }}
                    onKeyDown={() => {
                      setActiveMenuItem("show");
                      setIsOpen(false);
                    }}
                  >
                    SHOW
                  </Link>
                </div>
                <div className={styles.indented}>
                  <div>
                    <Link
                      className={classNames(
                        styles.navbarItem,
                        `${activeMenuItem === "show" ? "active" : ""}`,
                      )}
                      href="/show"
                      onClick={() => {
                        setActiveMenuItem("show");
                        setIsOpen(false);
                      }}
                      onKeyDown={() => {
                        setActiveMenuItem("show");
                        setIsOpen(false);
                      }}
                    >
                      CURRENT
                    </Link>
                  </div>
                  <div>
                    <Link
                      className={classNames(
                        styles.navbarItem,
                        `${activeMenuItem === "archive" ? "active" : ""}`,
                      )}
                      href="/archive"
                      onClick={() => {
                        setActiveMenuItem("archive");
                        setIsOpen(false);
                      }}
                      onKeyDown={() => {
                        setActiveMenuItem("archive");
                        setIsOpen(false);
                      }}
                    >
                      ARCHIVE
                    </Link>
                  </div>
                </div>
                {/* <Link className={`navbar-item ${activeMenuItem === "info" ? "active" : ""}`} to="/shop">SHOP</Link> */}
                <div>
                  <Link
                    className={classNames(
                      styles.navbarItem,
                      `${activeMenuItem === "alt" ? "active" : ""}`,
                    )}
                    href="/alt"
                    onClick={() => {
                      setActiveMenuItem("alt");
                      setIsOpen(false);
                    }}
                    onKeyDown={() => {
                      setActiveMenuItem("alt");
                      setIsOpen(false);
                    }}
                  >
                    ALT
                  </Link>
                </div>
                <div>
                  <Link
                    className={classNames(
                      styles.navbarItem,
                      `${activeMenuItem === "info" ? "active" : ""}`,
                    )}
                    href="/info"
                    onClick={() => {
                      setActiveMenuItem("info");
                      setIsOpen(false);
                    }}
                    onKeyDown={() => {
                      setActiveMenuItem("info");
                      setIsOpen(false);
                    }}
                  >
                    INFO
                  </Link>
                </div>
                <div>
                  <Link
                    className={classNames(
                      styles.navbarItem,
                      `${activeMenuItem === "shop" ? "active" : ""}`,
                    )}
                    href="/shop"
                    onClick={() => {
                      setActiveMenuItem("shop");
                      setIsOpen(false);
                    }}
                    onKeyDown={() => {
                      setActiveMenuItem("shop");
                      setIsOpen(false);
                    }}
                  >
                    SHOP
                  </Link>
                </div>
              </div>
              <div>
                <Link
                  className={classNames(
                    styles.navbarItem,
                    `${onOff === true ? "active" : ""}`,
                  )}
                  href="#"
                  onClick={() => {
                    setOnOff(!onOff);
                    // setIsOpen(false);
                  }}
                  onKeyDown={() => {
                    keyboardHandleOnOff();
                    // setIsOpen(false);
                  }}
                >
                  {!onOff ? "ON" : "OFF"}
                </Link>
              </div>
            </div>
          </div>

          {/* Desktop Menu */}
          <div id="navMenu" className={`${isOpen ? "" : "active"}`}>
            <div className={classNames(styles.navbarInner)}>
              <Link
                className={classNames(styles.navbarItem, `active`)}
                href="/"
                onClick={() => setActiveMenuItem("none")}
                onKeyDown={handleKeyboardDown}
              >
                NO
              </Link>
              <Link
                className={classNames(
                  styles.navbarItem,
                  `${activeMenuItem === "show" || activeMenuItem === "archive" ? "active" : ""}`,
                )}
                href="/show"
                onClick={() => setActiveMenuItem("show")}
                onKeyDown={handleKeyboardDown}
              >
                SHOW
              </Link>
              <Link
                className={classNames(
                  styles.navbarItem,
                  `${activeMenuItem === "alt" ? "active" : ""}`,
                )}
                href="/alt"
                onClick={() => setActiveMenuItem("alt")}
                onKeyDown={handleKeyboardDown}
              >
                ALT
              </Link>
              <Link
                className={classNames(
                  styles.navbarItem,
                  `${activeMenuItem === "info" ? "active" : ""}`,
                )}
                href="/info"
                onClick={() => setActiveMenuItem("info")}
                onKeyDown={handleKeyboardDown}
              >
                INFO
              </Link>
              <Link
                className={classNames(
                  styles.navbarItem,
                  `${activeMenuItem === "shop" ? "active" : ""}`,
                )}
                href="/shop"
                onClick={() => setActiveMenuItem("shop")}
                onKeyDown={handleKeyboardDown}
              >
                SHOP
              </Link>
              <div
                className={classNames(
                  styles.navbarItem,
                  styles.onOff,
                  `${onOff === false ? "inactive" : "active"}`,
                  "onOff",
                )}
                // href="/#"
                onClick={handleOnOff}
                // onKeyDown={keyboardHandleOnOff}
              >
                {!onOff ? "ON" : "OFF"}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Container>
  );
};

export { Navbar };
