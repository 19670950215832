"use client";

import { useState, useEffect } from "react";

import classNames from "classnames";

import { Navbar } from "../Navbar";
import * as styles from "./Header.module.scss";

const Header = () => {
  "use-client";

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
  // if (typeof window !== "undefined") {
  //   let prevScrollpos = window.scrollY;

  //   window.onscroll = function () {

  //     const maxScroll = document.body.clientHeight - window.innerHeight;
  //     let currentScrollPos = window.scrollY;
  //     if (
  //       (maxScroll > 0 &&
  //         prevScrollpos > currentScrollPos &&
  //         prevScrollpos <= maxScroll) ||
  //       (maxScroll <= 0 && prevScrollpos > currentScrollPos) ||
  //       (prevScrollpos <= 0 && currentScrollPos <= 0)
  //     ) {
  //       document.getElementById("noshow-header").style.top = "0";
  //     } else {
  //       document.getElementById("noshow-header").style.top = "-3.0rem"; // adjustable based your need
  //     }
  //     prevScrollpos = currentScrollPos;
  //   };
  // }

  // new function:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    // find current scroll position
    const currentScrollPos = window.scrollY;

    // set state based on location info (explained in more detail below)
    // setVisible(
    //   (prevScrollPos > currentScrollPos &&
    //     prevScrollPos - currentScrollPos > 2) ||
    //     currentScrollPos < 60,
    // );
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 60);

    const menuOffset =
      document.getElementById("noshow-header").offsetHeight / 2.4;

    if (!visible) {
      document.getElementById("noshow-header").style.top =
        "-" + menuOffset + "px";
      if (window.innerWidth < 768)
        document.getElementById("noshow-header").style.background = "none";
      document.getElementById("noshow-header").style.mixBlendMode = "normal";
    } else {
      document.getElementById("noshow-header").style.top = "0px";
      if (window.innerWidth < 768)
        document.getElementById("noshow-header").style.background = "white";
      document.getElementById("noshow-header").style.mixBlendMode = "normal";
    }
    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  useEffect(() => {
    const handleResize = () => {
      document.getElementById("noshow-header").style.background = "none";
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header id="noshow-header" className={classNames(styles.header)}>
      <div>
        <Navbar />
      </div>
    </header>
  );
};

export { Header };
