import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/ImposedAlphabet-Regular.woff2\",\"display\":\"swap\",\"variable\":\"--imposed-font\"}],\"variableName\":\"imposedFont\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/Layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/context/ArchiveProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/context/NSProvider/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/build/repo/src/components/layout/Header/index.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/globals.scss");
