"use client";

import React, { createContext, useState, useEffect } from "react";

const defaultValues = {
  currentArchiveTab: "all",
  viewByImage: false,
  year: "all",
  artist: "all",
};

export const ArchiveContext = createContext({});

type ProviderProps = {
  children: React.ReactNode; // 👈️ added type for children
};

const ArchiveProvider = ({ children }) => {
  const [currentArchiveTab, setCurrentArchiveTab] = useState(
    defaultValues.currentArchiveTab,
  );
  const [viewByImage, setViewByImage] = useState(defaultValues.viewByImage);
  const [year, setYear] = useState(defaultValues.year);
  const [artist, setArtist] = useState(defaultValues.artist);

  return (
    <ArchiveContext.Provider
      value={{
        viewByImage,
        setViewByImage,
        year,
        setYear,
        artist,
        setArtist,
        currentArchiveTab,
        setCurrentArchiveTab,
      }}
    >
      {children}
    </ArchiveContext.Provider>
  );
};

export default ArchiveProvider;

// "use client";

// import React, { createContext, useState, useEffect } from "react";

// const defaultValues = {
//   currentArchiveTab: "all",
//   viewByImage: false,
//   year: "all",
//   artist: "all",
// };

// export const ArchiveContext = createContext(defaultValues);

// type ProviderProps = {
//   children: React.ReactNode; // 👈️ added type for children
// };

// const ArchiveProvider = ({ children }: ProviderProps) => {
//   const [currentArchiveTab, setCurrentArchiveTab] = useState(
//     defaultValues.currentArchiveTab,
//   );
//   const [viewByImage, setViewByImage] = useState(defaultValues.viewByImage);
//   const [year, setYear] = useState(defaultValues.year);
//   const [artist, setArtist] = useState(defaultValues.artist);

//   return (
//     <ArchiveContext.Provider
//       value={{
//         viewByImage,
//         setViewByImage,
//         year,
//         // setYear,
//         artist,
//         // setArtist,
//         currentArchiveTab,
//         // setCurrentArchiveTab,
//       }}
//     >
//       {children}
//     </ArchiveContext.Provider>
//   );
// };

// export default ArchiveProvider;
