"use client";

import React, { createContext, useState, useEffect } from "react";

export const NSContext = createContext({});

const NSProvider = ({ children }) => {
  const [activeMenuItem, setActiveMenuItem] = useState("none");
  const [onOff, setOnOff] = useState(false);

  useEffect(() => {
    document.body?.classList?.remove(...document.body.classList);
    document.body?.classList?.add(onOff ? "on" : "off");
  }, [onOff]);

  return (
    <NSContext.Provider
      value={{ activeMenuItem, setActiveMenuItem, onOff, setOnOff }}
    >
      {children}
    </NSContext.Provider>
  );
};

export default NSProvider;

// "use client";

// import React, { createContext, useState, useEffect } from "react";

// export const NSContext = createContext({});

// type ProviderProps = {
//   children: React.ReactNode; // 👈️ added type for children
// };

// const NSProvider = ({ children }: ProviderProps) => {
//   const [activeMenuItem, setActiveMenuItem] = useState("none");
//   const [onOff, setOnOff] = useState(false);

//   useEffect(() => {
//     document.body?.classList?.remove(...document.body.classList);
//     document.body?.classList?.add(onOff ? "on" : "off");
//   }, [onOff]);

//   return (
//     <NSContext.Provider
//       value={{ activeMenuItem, setActiveMenuItem, onOff, setOnOff }}
//     >
//       {children}
//     </NSContext.Provider>
//   );
// };

// export default NSProvider;
