import React from "react";
import classNames from "classnames";

import * as styles from "./Container.module.scss";

const Container = ({
  tagName: El = "div",
  children,
  className = "",
  innerClassName = "",
  border = false,
  padded = false,
  narrowPadding = false,
  wrapped = false,
  recent = false,
  ...props
}) => {
  const cls = classNames(
    styles.container,
    { [styles.border]: border },
    { [styles.padded]: padded },
    { [styles.recent]: recent },
  );

  if (wrapped) {
    return (
      <El className={classNames(cls, styles.wrapped, className)} {...props}>
        <div className={classNames(styles.inner, innerClassName)}>
          {children}
        </div>
      </El>
    );
  }

  return (
    <El className={classNames(cls, className)} {...props}>
      {children}
    </El>
  );
};

export { Container };
